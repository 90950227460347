/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import CampHoc from '../../components/CampHoc';

// eslint-disable-next-line react/prop-types
const HaltinVaellus = ({ data }) => {
  const seo = {
    url: 'https://www.discoverlapland.fi/vapaalasku/kilpisjärven-vapaalaskuviikonloppu',
    image: `https://www.discoverlapland.fi${data.imageSEO.childImageSharp.gatsbyImageData.images.fallback.src}`,
    title: 'Kilpisjärven vapaalaskuviikonloppu 24.-26.4.2025',
    description:
      'Käsivarren alue on arktisten suurtunturien erämaata, jossa tunturien huiput kohoavat yli kilometrin korkeuteen. Tule huhtikuussa 2025 kanssamme nauttimaan pitkistä laskuista, piirtämään omat jälkesi koskemattomaan lumeen ja täyttämään mielesi vapaudella.',
  };

  return (
    <CampHoc
      heroImage={data.campBg.childImageSharp.gatsbyImageData}
      seo={seo}
      camp="kilpisjärven-vapaalaskuviikonloppu"
    >
      <div className="camp-text-content">
        <h2>Kilpisjärven vapaalaskuviikonloppu</h2>
        <h3>24.-26.4.2025</h3>
        <p className="bolded">
          Käsivarren alue on arktisten suurtunturien erämaata, jossa tunturien
          huiput kohoavat yli kilometrin korkeuteen. Tule huhtikuussa 2025
          kanssamme nauttimaan pitkistä laskuista, piirtämään omat jälkesi
          koskemattomaan lumeen ja täyttämään mielesi vapaudella.
        </p>
        <br />
        <p>
          Käsivarren erämaa-alueella, Lapin korkeimmilla huipuilla, on paljon
          tarjottavaa kokeneelle vapaalaskijalle. Laskupaikkamme löytyvät
          puuttomilta erämaan tuntureilta, joissa ollaan pitkälti
          matkapuhelinten kuuluvuusalueiden ulkopuolella. Nautimme joka päivä
          päivän parhaista lumista ja pitkistä laskuista upeissa maisemissa.
        </p>
        <br />
        <p>
          Ensimmäisenä laskupäivänä torstaina, 24.4.2024, laskemme Kilpisjärven
          lähituntureilla, jotka tarjoavat mainiota laskua sekä hienot maisemat
          Suomeen, Ruotsiin ja Norjaan. Kahtena seuraavana päivänä (perjantaina
          ja lauantaina) ajamme rekikyydillä syvälle erämaahan säiden salliessa.
          Matkan aikana ihailemme upeita maisemia ja Lapin talven rauhaa
          ympärillämme. Perillä laitamme kelkan parkkiin, skinit suksien
          pohjiin, tai lumikengät jalkaan ja lähdemme valloittamaan Suomen
          korkeimpia huippuja. Jos säät ja tähdet osuvat kohdilleen, käymme
          huiputtamassa mm. Suomen korkeimman tunturin Ridnitsohkkan (1 317,1
          metriä), josta on luvassa hieno lasku!
        </p>
      </div>
      <div className="offering-img-row">
        <GatsbyImage
          image={data.campImg1.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
        <GatsbyImage
          image={data.campImg2.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
      </div>
      <div className="camp-text-content">
        <p className="bolded">Oma laskutaitotasosi</p>
        <p>
          Tällä rennolla ja elämyksellisellä naisten vapaalaskuretkellä
          laskeminen tapahtuu pelkästään luonnonlumella, joten sinun tulee osata
          laskea hallitusti luonnonvaraisilla, ei hoidetuilla rinnealueilla sekä
          ymmärtää ja hyväksyä luonnonvaraisella rinteellä laskemiseen
          sisältyvät riskit. Pysymme poissa lumivyörymaastosta ja kaikilla tulee
          olla omat lumiturvallisuusvälineet mukana sekä vapaalaskuun riittävä
          vakuutus.
        </p>
        <br />
        <p className="bolded">Oppaat:</p>
        <p>
          <span className="bolded">Antte Lauhamaa</span> on lappilainen
          vapaalaskija, lumivyöryasiantuntija ja opas. Anten kokemus vuorilla
          liikkumisesta alkaa 90-luvun alkupuolelta, jolloin hän aloitti
          vapaalaskun telemark-suksilla. Antelle hiihtäminen ei ole pelkkä
          harrastus vaan kutsumus ja intohimo, jonka avulla voi toteuttaa
          itseään. Kilpisjärveltä kotoisin oleva Antte on CAA 2 -sertifioitu
          lumivyöryammattilainen. <br />
          <span className="bolded">Anette Latva-Piikkilä</span> on Discover
          Laplandin perustaja ja luontoelämysohjaaja.
        </p>
        <br />
        <p className="bolded">Mitä mukaan?</p>
        <p>
          Laskupäiville tarvitset sukset vaellussiteillä / splitboard /
          lumilauta + lumikengät, randomonot, nousukarvat, sauvat, kypärän,
          lumivyörypiipparin, lapion, sondin, taukotakin, juotavaa,
          energiapitoista evästä sekä repun, johon nämä kaikki mahtuvat.
        </p>
        <br />
        <p className="bolded">Mitä tarvitset mukaasi vaellukselle?</p>
        <p>
          - Vaellukseen sekä eri säihin sopivat vaatteet ja vaelluskengät <br />
          - Makuupussi / makuupussin sisälakana tuvassa nukkumiseen - Rinkka{' '}
          <br /> - Ruuantekovälineet / ruokatermos, ruokaa, vesipullo /
          termospullo <br /> - Kävely/vaellussauvat
        </p>
      </div>
      <GatsbyImage
        image={data.campImg3.childImageSharp.gatsbyImageData}
        className="offering-img"
        alt="image"
      />
      <div className="camp-text-content">
        <br />
        <p className="bolded">Vapaalaskuviikonlopun hinta on 670€ sis. alv</p>
        <p>
          Johon kuuluu: <br />
          - Päivän parhaat lumet ja laskut! <br />
          - Opastus kolmelle laskupäivälle <br />
          - Päiväkohtaiset suunnitelmat, joihin vaikuttaa sää, lumiturvallisuus
          sekä lumitilanne <br />- Rekikyydit erämaahan + lämpimät haalarit ja
          kypärä kyydityksiin
          <br />
          <br />
          Vapaalaskuviikonlopun maksimi määrä on kuusi ja minimimäärä viisi,
          jotta viikonloppu toteutuu.
          <br />
          <br />
          Jokainen on vastuussa matkustamisesta Kilpisjärvelle, ruokailusta ja
          yöpymisesta. Jos sinulla on näihin kysyttävää, laita Anetelle viestiä.
          Anette & Antte yöpyvät itse Lapland Hotels Kilpiksessä, joista päivän
          retket alkavat.
        </p>
        <br />
        <p>
          <span className="bolded">
            Ilmoittaudu mukaan sähköpostitse{' '}
            <a href="mailto: anettelatvapiikkila@gmail.com">
              anettelatvapiikkila@gmail.com
            </a>{' '}
            ja toimi näin:
          </span>
          <br />
          OTSIKKO: Kilpisjärven vapaalaskuviikonloppu 24.-26.4.2025
          <br />
          Vastaa meilissä ao. kysymyksiin:
          <br />
          Koko nimi:
          <br />
          Ikä:
          <br />
          Puhelinnumero:
          <br />
          Paikkakunta:
          <br />
          Mikä on aikaisempi kokemuksesi vapaalaskusta?
          <br />
          Onko sinulla lumiturvallisuuskurssi käytynä?
          <br />
          Mikä on terveydentilasi (onko polvi/nivelongelmia, astmaa jne.)?
          <br />
          <br />
          Tekisikö mieli mukaan tälle ainutlaatuiselle vapaalaskuretkelle
          Kilpisjärvelle? Mikäli sinulla on kysyttävää, voit olla yhteydessä
          Anetteen joko meilitse{' '}
          <a href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>{' '}
          tai soittaa 040 772 4124.
          <br />
          <br />
          <span className="bolded">
            Tervetuloa mukaan Käsivarren erämaahan kokemaan ja näkemään Lapin
            parhaat vapaalaskupaikat!
          </span>
        </p>
      </div>
    </CampHoc>
  );
};

export const query = graphql`
  {
    campBg: file(
      relativePath: { eq: "images/freeski/kilpisjarvi-freeski-1.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg1: file(
      relativePath: { eq: "images/freeski/Freeski-Kilpis-3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg2: file(
      relativePath: { eq: "images/freeski/kilpis-freeski-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg3: file(
      relativePath: { eq: "images/freeski/Freeski-Kilpis-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imageSEO: file(
      relativePath: { eq: "images/freeski/kilpisjarvi-freeski-1.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1366, layout: FIXED)
      }
    }
  }
`;

export default HaltinVaellus;
